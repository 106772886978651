import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import UrlShortener from './components/UrlShortener';
import QRGenerator from './components/QRGenerator';
import imagen from './Logo-negro.png';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/page/login" element={<Login />} />
        <Route 
          path="/page/app" 
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          } 
        />
        <Route path="/page/shortener" element={
            <ProtectedRoute>
              <UrlShortener />
            </ProtectedRoute>
          } 
        />
        <Route path="/page/qr" element={
            <ProtectedRoute>
              <QRGenerator />
            </ProtectedRoute>
          } 
        />
        <Route path="/" element={<Navigate to="/page/login" />} />
      </Routes>
    </Router>
  );
}

function ProtectedRoute({ children }) {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  return isLoggedIn ? children : <Navigate to="/page/login" />;
}

function HomePage() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    navigate('/page/login');
  };

  return (
    <div className="home-page">
      <img src={imagen} alt='logo' height={'120px'} width={'120px'} className="logo-negro"></img>
      <button onClick={handleLogout} className="logout-button">Log Out</button>
      <div className="home-page-content">
        <div className="left-half">
          <button 
            className="button-app button-black" 
            onClick={() => navigate('/page/shortener')}
          >
            Go to Link Shortener
          </button>
        </div>
        <div className="right-half">
          <button 
            className="button-app button-white" 
            onClick={() => navigate('/page/qr')}
          >
            Go to QR Generator
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
